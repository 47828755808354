<template>
    <div class="settings--container">
        <div class="profile-info--container">
            <p class="name__text">{{account.fullName}}</p>
            <p class="email__text">{{account.email}}</p>
            <p class="classes--container classes__text"><strong>Ученики</strong> | <strong>{{stringClasses}}</strong></p>
        </div>
        <div class="settings--list-container">
            <div class="settings--list-item" @click="goToClasses">
                <img class="settings__icon" src="../../assets/icons/classes-settings-icon.svg">
                <p class="settings__text">Родительский кабинет</p>
            </div>
            <div class="settings--list-item" @click="goToProfile">
                <img class="settings__icon" src="../../assets/icons/settings-profile-icon.svg">
                <p class="settings__text">Настройки профиля</p>
            </div>
<!--            <div class="settings-push&#45;&#45;container">-->
<!--                <p class="title__text title&#45;&#45;container">Уведомления о начале и конце:</p>-->
<!--                <div class="switch&#45;&#45;container">-->
<!--                    <p>Учебного дня</p>-->
<!--                    <graphic-switch :checked="isPushEnabled" v-on:change="onChangePushDay"></graphic-switch>-->
<!--                    <p>{{isPushEnabled}}</p>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="settings&#45;&#45;list-item" @click="goToFaq">-->
<!--                <img class="settings__icon" src="../../../assets/icons/settings-faq-icon.svg">-->
<!--                <p class="settings__text">FAQ</p>-->
<!--            </div>-->
            <div class="settings--list-item" @click="exit">
                <img class="settings__icon" src="../../assets/icons/settings-exit-icon.svg">
                <p class="settings__text">Выход</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SettingsMainListView",
        data(){
            return {
                isLoad : true,
            }
        },
        computed:{
            isPushEnabled(){

              return this.$store.getters.IS_PUSH_ENABLED
            },
            account(){
                return this.$store.getters.PROFILE
            },
            stringClasses(){
                let str= ""
                for(let cl of this.account.myClasses){
                  if (str === ""){
                    str+= (cl.type === 0) ? cl.fullName : cl.fullName+"гр"
                    continue
                  }

                  str+=", "
                  str+= (cl.type === 0) ? cl.fullName : cl.fullName+"гр"
                }
                return str
            }
        },
        methods:{
            async goToClasses(){
                await this.$router.push({name:"SettingsClassesView"})

            },

            async goToProfile(){
                await this.$router.push({name:"SettingsProfileView"})

            },


            async goToFaq(){
                await this.$router.push({name:"SettingsFaqView"})

            },

            onChangePushDay(checked){
                if (checked){
                    if (this.isPushEnabled){
                        console.log("sos")
                    }  else{
                        window.webkit.messageHandlers.doStuffMessageHandler.postMessage("pushNotGranted");
                    }
                }
            },

            async exit(){
                const res = await this.$store.dispatch("EXIT_FROM_ACCOUNT");
                if (res){
                    await this.$store.dispatch("RESET_STATE_TO_DEFAULT")
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    this.$router.push({name: "AuthView"})

                    // document.location.href = '/auth'

                }
            }
        },
        async mounted() {
            await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
        }

    }
</script>

<style scoped lang="scss">
    .settings--container{
        width: 100%;
        height: 100%;


        .profile-info--container{
            margin-left: 26px;
            margin-top: 22px;

            .name__text{
                font-family: Roboto, "sans-serif";
                letter-spacing: 0;
                font-size: 26px;
                color: black;
                line-height: 30px;
            }


            .email__text{
                font-family: Roboto, "sans-serif";
                letter-spacing: 0;
                font-size: 15px;
                color: #4A90E2;
                line-height: 18px;
            }

            .classes--container{
                margin-top: 30px;
            }

            .classes__text{
                font-family: Roboto, "sans-serif";
                letter-spacing: 0;
                font-size: 14px;
                color: #00264B;
                line-height: 16px;
            }
        }

        .settings--list-container{
            margin-top: 37px;
            margin-left: auto;
            margin-right: auto;
            width: 343px;


            .settings--list-item{
                width: 343px;
                height: 64px;
                display: flex;
                align-items: center;
                box-shadow: 0 2px 3px 0 rgba(162,162,162,.2);
                margin-top: 16px;
                background: white;
                border-radius: 6px;


                .settings__icon{
                    margin-left: 16px;
                    width: 32px;
                    height: 32px;
                }

                p{
                    margin-left: 16px;
                }

                .settings__text{
                    font-size: 16px;
                    font-family: Roboto, "sans-serif";
                    font-weight: 500;
                    line-height: 19px;
                    color: #0D1904;
                    &.settings__exit{
                        color: #E64736;
                    }
                }
            }

            .settings--list-item:first-child{
                margin-top: 0;
            }

            .settings--list-item:active{
                opacity: 0.75;
            }
        }
    }

    .settings-push--container{
        margin: 41px auto 64px;
        width: 274px;

        .title__text{
            font-weight: 600;
            font-family: Roboto, "sans-serif";
            font-size: 16px;
            letter-spacing: 0.35px;
            line-height: 14px;
            color: #0D1904;

        }

        .switch--container{
            display: flex;
            margin-top: 40px;
            p{
                width: 140px;
                font-family: Roboto, "sans-serif";
                font-weight: 100;
                letter-spacing: 0.35px;
                font-size: 16px;
                margin-right: 51px;
            }
        }
    }
</style>
